<template>
    <div class="card-ticket">
        <div
            class="trang-thai"
            :style="` background-color: ${item.maMauTrangThai}; border-color: ${item.maMauTrangThai};`"
            v-if="item.idTrangThai == $t('TrangThaiLenh.KhongThucHienHanhTrinh')"
        >
            Không thực hiện
        </div>
        <div
            class="trang-thai"
            :style="` background-color: ${item.maMauTrangThai}; border-color: ${item.maMauTrangThai};`"
            v-else-if="item.idTrangThai == $t('TrangThaiLenh.KhongHoanThanhHanhTrinh')"
        >
            Không hoàn thành
        </div>
        <div
            class="trang-thai"
            :style="` background-color: ${item.maMauTrangThai}; border-color: ${item.maMauTrangThai};`"
            v-else-if="item.idTrangThai == $t('TrangThaiLenh.HoanThanh')"
        >
            Đã hoàn thành
        </div>
        <div
            class="trang-thai"
            :style="` background-color: ${item.maMauTrangThai}; border-color: ${item.maMauTrangThai};`"
            v-else-if="item.idTrangThai == $t('TrangThaiLenh.KhongDuDieuKienXuatBen')"
        >
            Không đủ điều kiện
        </div>
        <div
            class="trang-thai"
            :style="` background-color: ${item.maMauTrangThai}; border-color: ${item.maMauTrangThai};`"
            v-else
        >
            {{ item.tenTrangThai }}
        </div>
        <div class="row">
            <div class="xs12 pa-3 pb-2">
                <div :class="rowText">
                    <div
                        class="mr-2"
                        :class="item.laBenDi ? 'color-chieuDi' : 'color-chieuVe'"
                    >
                        <i class="mdi mdi-calendar-clock"></i>
                    </div>
                    <div :class="textRight">
                        {{
                            moment(item.thoiGianXuatBenKeHoach).format("HH:mm DD/MM/yyyy")
                        }}
                    </div>
                </div>
                <div :class="rowText" class="justify-space-between">
                    <div class="row align-center">
                        <div
                            class="mr-2"
                            :class="item.laBenDi ? 'color-chieuDi' : 'color-chieuVe'"
                        >
                            <i class="mdi mdi-card-bulleted-outline"></i>
                        </div>
                        <div :class="textRight">
                            <div class="row align-center">
                                {{ item.bienKiemSoat }}
                                (<span @click="XemBanTheHienLenh(item)">
                                    <div class="row align-center text-link">
                                        {{
                                            item.laLenhGiay && item.maLenh.length > 12
                                                ? item.maLenh.substr(0, 10) + "..."
                                                : item.maLenh
                                        }}
                                        <i
                                            class="mdi mdi-file-document"
                                            v-if="item.laLenhGiay"
                                        ></i>
                                    </div> </span
                                >)
                            </div>
                        </div>
                        <!-- <DxButton
                            styling-mode="text"
                            icon="mdi mdi-download"
                            @click="downloadImg(item)"
                        /> -->
                    </div>
                </div>
                <div class="row">
                    <div
                        :class="
                            item.idTrangThai == $t('TrangThaiLenh.ChoBenDiKy')
                                ? 'grow'
                                : 'xs12'
                        "
                    >
                        <div
                            :class="
                                rowText +
                                (item.laBenDi ? ' color-chieuDi' : ' color-chieuVe')
                            "
                        >
                            <div class="mr-2">
                                <i class="mdi mdi-bus-stop"></i>
                            </div>
                            <div :class="textRight">
                                {{ `${item.benDi}` }}
                            </div>
                        </div>
                        <div :class="rowText">
                            <div
                                class="mr-2"
                                :class="item.laBenDi ? 'color-chieuDi' : 'color-chieuVe'"
                            >
                                <i class="mdi mdi-map-marker-down"></i>
                            </div>
                            <div :class="textRight">
                                {{ `${item.benDen}` }}
                            </div>
                        </div>
                        <div :class="rowLastText">
                            <div
                                class="mr-2"
                                :class="item.laBenDi ? 'color-chieuDi' : 'color-chieuVe'"
                            >
                                <i class="mdi mdi-card-account-details-outline"></i>
                            </div>
                            <div :class="textRight">
                                {{ item.tenLaiXe }}
                            </div>
                        </div>
                    </div>
                    <!-- <div
                        class="shrink mx-2"
                        style="width: 98px !important; height: 100px !important"
                        v-if="
                            item.idTrangThai == $t('TrangThaiLenh.ChoBenDiKy') ||
                            item.idTrangThai == $t('TrangThaiLenh.BenDiDaKy') ||
                            item.idTrangThai == $t('TrangThaiLenh.ChoXacNhanTraKhach')
                        "
                    >
                        <div class="row justify-center">
                            <img
                                @click="XemBanTheHienLenh(item)"
                                v-if="item && item.qrCode"
                                :src="QrCodeLink"
                                style="
                                    width: 98px !important;
                                    height: 100px !important;
                                    max-width: unset;
                                "
                                alt=""
                            />
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="row" style="border-top: 1px solid #dadce0; padding: 0 2px">
            <div class="xs12 text-xs-center">
                <DxButton
                    text="Tiếp nhận"
                    type="default"
                    styling-mode="text"
                    width="100%"
                    @click="NhanLenh()"
                />
            </div>
        </div>
    </div>

    <PopupVue
        height="auto"
        v-if="ifPopupXacNhanChuyenLenh"
        v-model:dialog="PopupXacNhanChuyenLenh"
        title=""
        buttonTextLeft="Hủy"
        class="popup-padding-8"
        @close="XacNhanChuyenLenh"
        heightScrollView="auto"
    >
        <template #content>
            <ThongBaoVue
                :message="ParamThongBao.message"
                :state="ParamThongBao.state"
                :title="ParamThongBao.title"
            />
        </template>
    </PopupVue>

    <PopupVue
        height="auto"
        v-if="ifPopupTiepNhanLenh"
        v-model:dialog="PopupTiepNhanLenh"
        :title="titleNhanLenh"
        buttonTextRight="Xác nhận"
        buttonTextLeft="Hủy"
        heightScrollView="0px"
        @close="TiepNhanLenh"
        classPopup="popup-padding-16"
    >
    </PopupVue>

    <PopupBanTheHienLenhVue
        v-if="ifPopupBanTheHienLenh"
        :dialog="PopupBanTheHienLenh"
        :guidLenh="item.guidLenh"
        :laLenhGiay="false"
        :danhSachLenhGiay="[]"
        @closes="
            () => {
                PopupBanTheHienLenh = false;
            }
        "
    />
</template>

<script>
import { DxButton } from "devextreme-vue";
import ThongBaoVue from "@/components/_Common/ThongBao.vue";
import PopupVue from "@/components/_Common/Popup.vue";
// import LenhDienTu from "../../../../../class/LenhDienTu";
import LaiXe from "../../../../../class/LaiXe";
import PopupBanTheHienLenhVue from "../../../Quan-Ly-Lenh/components/PopupBanTheHienLenh";
export default {
    components: {
        DxButton,
        ThongBaoVue,
        PopupVue,
        PopupBanTheHienLenhVue,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            rowText: "row align-center row-text",
            rowLastText: "row align-center row-text",
            textRight: " font-medium",
            ifPopupXacNhanChuyenLenh: false,
            PopupXacNhanChuyenLenh: false,
            ifPopupTiepNhanLenh: false,
            PopupTiepNhanLenh: false,
            PopupBanTheHienLenh: false,
            ifPopupBanTheHienLenh: false,
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
            DanhSachLenhCanHoanThanh: [],
            ChiTietLenhDangThucHien: {},
        };
    },
    computed: {
        QrCodeLink() {
            if (!this.item || !this.item?.qrCode) return "#";
            // let fcolor = this.renderColorQr(this.item?.laBenDi);
            let qrdata = this.item?.qrCode;
            return (
                `https://image-charts.com/chart?` +
                [
                    "cht=qr",
                    "icqrb=FFFFFF00",
                    // "icqrf=" + fcolor,
                    "chs=88x88",
                    "choe=UTF-8",
                    "chld=L",
                    "chl=" + qrdata,
                ].join("&")
            );
        },
        ToaDo() {
            return this.$store.state.ThongTin.ToaDo;
        },
    },
    watch: {
        PopupXacNhanChuyenLenh() {
            if (!this.PopupXacNhanChuyenLenh) {
                this.NgatDOMPopup("ifPopupXacNhanChuyenLenh");
            }
        },
        PopupTiepNhanLenh() {
            if (!this.PopupTiepNhanLenh) {
                this.NgatDOMPopup("ifPopupTiepNhanLenh");
            }
        },
        PopupBanTheHienLenh() {
            if (!this.PopupBanTheHienLenh) {
                this.NgatDOMPopup("ifPopupBanTheHienLenh");
            }
        },
    },
    methods: {
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        NgatDOMPopup(key) {
            setTimeout(() => {
                this[key] = false;
            }, 300);
        },
        XemBanTheHienLenh() {
            this.showPopup("PopupBanTheHienLenh");
            // this.$emit("XemBanTheHienLenh", item);
        },
        // async downloadImg(item) {
        //     let lenh = new LenhDienTu();
        //     lenh.guidLenh = item.guidLenh;
        //     let link = await lenh.layLinkXemBanTheHienLenh();
        //     let domain = "https://slaixe.sbus.vn/tai-lenh/";
        //     return (
        //         SonPhat.hasOwnProperty("openExternal") &&
        //         SonPhat.openExternal(domain + `?link=${link}`)
        //     );
        // },
        NhanLenh() {
            return this.KiemTraTruocKhiTiepNhanLenh();
        },
        async KiemTraTruocKhiTiepNhanLenh() {
            try {
                let rs = await this.$Events.RequestGet({
                    ServicesBE: "LenhMobile",
                    url: this.$t("urlLXQuanLyLenh.LaiXeKiemTraTruocKhiTiepNhanLenh"),
                    dataGet: null,
                    msgSuccess: "",
                    msgError: "",
                    msgCatch: "",
                });
                if (!rs.status) {
                    if (rs.errorCode == 1214) {
                        this.DanhSachLenhCanHoanThanh = rs.data || [];
                        localStorage.setItem(
                            "DanhSachLenhCanHoanThanh",
                            JSON.stringify(this.DanhSachLenhCanHoanThanh),
                        );
                        this.$router.push({
                            path: "/Danh-Sach-Lenh-Can-Hoan-Thanh",
                            query: {
                                prePath: "/Nhan-Lenh-Sau-Khi-Tao-Lenh",
                            },
                        });
                        this.$Helper.ThongBaoToast(
                            "warning",
                            "Lái xe có chuyến đi chưa hoàn thành!",
                        );
                    } else if (rs.errorCode == 1215) {
                        if (rs.data && rs.data.length > 0) {
                            this.ChiTietLenhDangThucHien = rs.data[0];
                            this.ThongBaoXacNhanChuyenLenh();
                        } else {
                            this.$Helper.ThongBaoPopup({
                                message_title:
                                    "Lấy danh sách hành khách mua vé trên chuyến đi đang thực hiện gặp lỗi!",
                                message: "Không tìm thấy chuyến đi đang thực hiện!",
                            });
                        }
                    } else {
                        this.$Helper.ThongBaoToast("error", rs.message);
                    }
                } else {
                    this.titleNhanLenh = `Bạn có chắc muốn tiếp nhận lệnh điện tử ${this.item.maLenh}?`;
                    this.showPopup("PopupTiepNhanLenh");
                }
            } catch (error) {
                console.log("🚀 ~ file: error:", error);
            }
        },
        ThongBaoXacNhanChuyenLenh() {
            this.ParamThongBao.title = `Bạn đang thực hiện lệnh khác!`;
            this.ParamThongBao.message = `Bạn có chắc chắn muốn chuyển sang lệnh?`;
            this.ParamThongBao.state = "Warning";
            this.showPopup("PopupXacNhanChuyenLenh");
        },
        async XacNhanChuyenLenh(param) {
            if (!param) {
                this.PopupXacNhanChuyenLenh = false;
                return;
            }
            this.LaiXeChuyenDoiChuyenDi();
        },
        async LaiXeChuyenDoiChuyenDi() {
            try {
                this.$startLoading;
                let query = {
                    idLenhCu: this.ChiTietLenhDangThucHien.idLenhDienTu,
                    idLenhMoi: this.item.guidLenh,
                    idDonHangs: [],
                    ToaDo: this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                };
                let rs = await this.$store.dispatch("Lenh/LaiXeChuyenDoiChuyenDi", query);
                if (rs.Data.status == true) {
                    this.PopupXacNhanChuyenLenh = false;
                    this.$Helper.ThongBaoToast("success", "Chuyển đổi lệnh thành công!");
                    this.$router.go(-1);
                    setTimeout(() => {
                        this.QuayLaiChiTietLenh();
                    }, 100);
                } else {
                    this.$Helper.ThongBaoPopup({
                        message_title: "Chuyển đổi lệnh thất bại!",
                        message: rs.Data.message,
                    });
                }
            } catch (ex) {
                console.log("🚀 ex", ex);
                this.$Helper.ThongBaoToast("error", "Lỗi hệ thống client!");
            } finally {
                this.$stopLoading;
            }
        },
        async TiepNhanLenh(param) {
            if (!param) {
                this.PopupTiepNhanLenh = false;
                return;
            }
            try {
                this.$startLoading;
                let rs = await new LaiXe().tiepNhanLenh(
                    this.item.guidLenh,
                    this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                );
                this.PopupTiepNhanLenh = false;

                if (!rs) {
                    throw this.$Helper.ThongBaoToast("error", "Lỗi hệ thống client!");
                }
                if (!rs.Data.status) {
                    this.$Helper.ThongBaoToast("error", rs.Data.message);
                    return;
                }

                this.$Helper.ThongBaoToast("success", "Tiếp nhận lệnh thành công!");
                this.$router.go(-1);
                setTimeout(() => {
                    this.QuayLaiChiTietLenh();
                }, 300);
            } catch (error) {
                console.log("🚀 ~ error:", error);
                this.$Helper.ThongBaoToast("error", "Lỗi hệ thống client!");
            } finally {
                this.$stopLoading;
            }
        },
        QuayLaiChiTietLenh() {
            this.$router.push({
                path: "/tabs/LenhDienTu",
                query: {
                    isChiTiet: true,
                    guidLenh: this.item.guidLenh,
                },
            });
        },
    },
};
</script>

<style scoped>
.card-ticket {
    position: relative;
    box-shadow: 0px 2px 9px #ccc;
    overflow: hidden;
}
.row-text {
    word-break: break-word;
    white-space: break-spaces;
}

.color-chieuDi {
    color: #03a9f4;
}
.color-chieuVe {
    color: #13b000;
}
.trang-thai {
    position: absolute;
    top: 36px;
    right: -64px;
    transform: rotate(45deg);
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 4px 0;
    width: 224px;
    text-align: center;
    z-index: 1;
    opacity: 0.8;
    color: #fff;
}
</style>
