<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                                @click="QuayLai"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center" :style="'opacity: 1'">
                            NHẬN LỆNH
                        </div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                type="default"
                                icon="mdi mdi-magnify"
                                class="ml-1"
                            />
                        </div>
                    </slot>
                </div>
            </ion-toolbar>
        </ion-header>

        <ion-content :scroll-events="false" class="content-box">
            <div class="px-2 pt-2">
                <DxList
                    :data-source="DanhSachLenhCuaLaiXeSauKhiKyLenh"
                    ref="DanhSachLenhCuaLaiXeSauKhiKyLenh"
                    height="calc(100% - 8px)"
                    page-load-mode="scrollBottom"
                    key-expr="id"
                    :hoverStateEnabled="false"
                    :focusStateEnabled="false"
                    :activeStateEnabled="false"
                    no-data-text="Không có dữ liệu!"
                    class="list-lenh-style"
                >
                    <template #item="{ data: item }">
                        <div>
                            <!-- Bổ sung div để sửa lỗi khi build bị vỡ giao diện -->
                            <FrameItemVue :item="item" @NhanLenh="NhanLenh" />
                        </div>
                    </template>
                </DxList>
            </div>

            <PopupVue
                height="auto"
                v-if="ifPopupMatMaKyLenh"
                v-model:dialog="PopupMatMaKyLenh"
                title="Nhập mã bảo mật"
                buttonTextRight="Xác nhận"
                buttonTextLeft="Hủy"
                @close="XacNhanKyLenh"
                classPopup="popup-padding-bottom-8 popup-padding-right-16 popup-padding-left-16"
            >
                <template #content>
                    <!-- <PopupMatMaKyLenhVue ref="PopupMatMaKyLenhVue" /> -->
                </template>
            </PopupVue>
        </ion-content>
    </ion-page>
</template>
<script>
import { IonPage, IonHeader, IonContent, IonToolbar } from "@ionic/vue";
import { onIonViewWillEnter } from "@ionic/vue";
import { DxButton, DxList } from "devextreme-vue";
import PopupVue from "../../../components/_Common/Popup.vue";
import FrameItemVue from "./components/FrameItem.vue";
export default {
    components: {
        DxList,
        IonPage,
        IonHeader,
        IonContent,
        IonToolbar,
        PopupVue,
        DxButton,
        FrameItemVue,
    },
    data() {
        return {
            ifPopupMatMaKyLenh: false,
            PopupMatMaKyLenh: false,
        };
    },
    computed: {
        DanhSachLenhCuaLaiXeSauKhiKyLenh: {
            get() {
                return this.$store.state.DVVTLenh.DanhSachLenhCuaLaiXeSauKhiKyLenh || [];
            },
            set(data) {
                this.$store.commit("DVVTLenh/Set", {
                    key: "DanhSachLenhCuaLaiXeSauKhiKyLenh",
                    data: data,
                });
            },
        },
    },
    watch: {
        PopupMatMaKyLenh() {
            if (!this.PopupMatMaKyLenh) {
                this.NgatDOMPopup("ifPopupMatMaKyLenh");
            }
        },
    },
    methods: {
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        NgatDOMPopup(key) {
            setTimeout(() => {
                this[key] = false;
            }, 300);
        },
        QuayLai() {
            this.$router.go(-1);
        },
        LayThongTinLenhSauKhiTaoLenh() {
            this.$router.go(-1);
            // setTimeout(() => {
            //     this.$router.push("/Bao-Cao-Su-Dung-Lenh");
            // }, 100);
        },
    },
    created() {
        onIonViewWillEnter(async () => {
            setTimeout(() => {
                this.$refs.DanhSachLenhCuaLaiXeSauKhiKyLenh.instance.repaint();
            }, 500);
        });
    },
    mounted() {},
};
</script>
<style scoped></style>
